import html2pdf from 'html2pdf.js';
import * as Handlebars from "handlebars";
import {getUrl} from "aws-amplify/storage";


export function htmlToPDF(dataSource, meta, template, parameters) {

    // HELPERS

    // extract countries from their 2 digit code
    Handlebars.registerHelper('countryNameFromCode', function (code) {
        const countryMap = meta.countryCodes["en-gb"]
        return countryMap[code] || 'Unknown';
    });

    // format date strings
    Handlebars.registerHelper('formatDate', function (dateString) {
        if (!dateString) return 'N/A'; // Handle empty cases

        const options = {day: 'numeric', month: 'long', year: 'numeric'}; // Full month name
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // 'en-GB' for British English formatting
    });

    // get the signed URL for a specific file
    Handlebars.registerHelper('getFileUrl', function (dataSource, dataKey) {

        // Check if dataSource is valid
        if (!Array.isArray(dataSource)) {
            console.warn(`Invalid dataSource for dataKey: ${dataKey}. Expected an array.`);
            return new Handlebars.SafeString(`<span>No files available</span>`);
        }

        // Find the file in the dataSource array that matches the dataKey
        const myFile = dataSource.find(file => file.dataKey === dataKey);
console.log(myFile)
        if (myFile) {
            // Return a placeholder link
            return new Handlebars.SafeString(`<span class="file-link" file-key="${myFile.fileKey}" data-key="${myFile.dataKey}">${myFile.name || 'Download File'}</span>`);
        }

        return new Handlebars.SafeString(`<span>No file found for ${dataKey}</span>`);
    });

    (async () => {
        try {
            console.log("Processing single submission to PDF...");
            console.log("Template", template);

            let applicantSurname = "";

            // Generate HTML content from the provided template for the single submission
            // const htmlContent = generateHTMLFromTemplate(template, submission);
            const compiledTemplate = Handlebars.compile(template);
            const htmlContent = compiledTemplate(dataSource);

            // Create a container element to store the generated HTML
            const htmlContainer = document.createElement('div');
            htmlContainer.innerHTML = htmlContent;

            // Generate signed URLs for all file links in the HTML
            const fileLinks = htmlContainer.querySelectorAll('.file-link');
            for (const link of fileLinks) {
                const fileKey = link.getAttribute('file-key');
                const dataKey = link.getAttribute('data-key');
                console.log(dataKey)
                // const fileKeyNoExtension = fileKey.split('.')[0]; // Assuming the dataKey is the name before the first dot

                // Extract the file extension for the filename
                const fileExtension = fileKey.split('.').pop();
                const applicationNumber = dataSource.meta.trackingNumber;

                // Correctly extract the applicant's surname
                applicantSurname = dataSource.data.submission.meta.owner?.familyName || '';

                // Construct the filename
                const formattedFileName = `Application_${applicationNumber}_${applicantSurname}_${dataKey}.${fileExtension}`;

                try {
                    const signedUrlObj = await getUrl({key: fileKey});
                    const signedUrl = signedUrlObj.url || signedUrlObj.href; // Access the correct property here

                    // Update the link to open in a new tab with the correct filename
                    link.innerHTML = `<a href="${signedUrl}" target="_blank" download="${formattedFileName}">Click to download</a>`;

                } catch (error) {
                    console.error(`Error generating signed URL for ${fileKey}:`, error);
                    link.innerHTML = 'Error generating link'; // Fallback text
                }
            }

            // Continue with PDF generation
            const filename = parameters?.filename + "_" + (dataSource.meta?.trackingNumber ? dataSource.meta.trackingNumber : dataSource.id) + "_" + applicantSurname;

            const options = {
                margin: [20, 30, 10, 30], //top, left, buttom, right,
                filename: filename + ".pdf", // Append ".pdf" extension
                image: {type: 'jpeg', quality: 0.98},
                html2canvas: {scale: 2},
                jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
            };

            // Generate the PDF from the container's HTML
            html2pdf().from(htmlContainer).set(options).save();

        } catch (error) {
            console.error("Error processing submission data:", error);
        }
    })();

    /*

        // 2. Compile the Handlebars template with dataSource
        console.log(template, typeof template)
        const compiledTemplate = Handlebars.compile(template);
        const content = compiledTemplate(dataSource);

        // 3. Convert the compiled HTML content to a PDF and download it
        const options = {
            margin: 1,
            filename: parameters?.filename  + ".pdf" || 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };

        html2pdf()
            .from(content)
            .set(options)
            .save();*/
}

