import { useEffect, useMemo } from 'react';

export default function Maths(props) {
    const { controlStates = {}, control, handleInputChange } = props; // Default to empty object if undefined
    const attributes = JSON.parse(control?.attributes || "{}");

    const total = useMemo(() => {
        let computedTotal = 0;
        if (attributes.fields && Array.isArray(attributes.fields)) {
            attributes.fields.forEach(field => {
                const fieldState = controlStates[field];
                const value = fieldState ? fieldState.value : 0; // Default to 0 if undefined
                if (typeof value === 'number') {
                    computedTotal += value;
                }
            });
        }
        return computedTotal;
    }, [controlStates, attributes.fields]);

    useEffect(() => {
        // Only update if controlStates and handleInputChange are available
        if (controlStates && handleInputChange) {
            handleInputChange(control.key, total);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total]);

    return (
        <>
            <strong>Total: {total}</strong>
        </>
    );
}
