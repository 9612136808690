import React, {useState, useEffect} from "react";
import {signIn} from "aws-amplify/auth";
import {getCurrentUser} from "aws-amplify/auth";

const AutoSignIn = ({children}) => {
    // State to track whether we are still attempting to sign in
    const [isSigningIn, setIsSigningIn] = useState(true);
    const [isError, setIsError] = useState(false);
    const params = new URLSearchParams(window.location.search);
    const username = params.get("u");
    const password = "Hyperionlive.123";

    useEffect(() => {

        async function checkUser(username, password) {
            try {
                const currentUser = await getCurrentUser();
                console.log('User is already signed in:', currentUser);
                setIsSigningIn(false);
            } catch (error) {
                const signInUser = async () => {
                    try {
                        await signIn({username, password});
                        console.log("User signed in automatically");
                    } catch (error) {
                        console.error("Error signing in automatically:", error);
                    } finally {
                        setIsSigningIn(false);
                    }
                };
                signInUser();
            }
        }

        if (window.location.pathname.split("/")[1]) {
            checkUser(username, password)
        } else {
            setIsError(true)
            setIsSigningIn(false)
        }
    }, []);

    // If we are still signing in, show the "Processing your login" message
    if (isSigningIn) {
        return <div>Processing your login</div>;
    }

    // If there's an error ...
    if (isError) {
        return <div>Could not log you in</div>;
    }

    // Once signing in is finished, render the children components
    return <>{children}</>;
};

export default AutoSignIn;
