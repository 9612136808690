// https://unstats.un.org/unsd/methodology/m49/overview/

export const countryCodes = {
    "en-gb": [
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Algeria",
            "code": "DZ",
            "3code": "DZA"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Egypt",
            "code": "EG",
            "3code": "EGY"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Libya",
            "code": "LY",
            "3code": "LBY"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Morocco",
            "code": "MA",
            "3code": "MAR"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Sudan",
            "code": "SD",
            "3code": "SDN"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Tunisia",
            "code": "TN",
            "3code": "TUN"
        },
        {
            "region": "Africa",
            "sub_region": "Northern Africa",
            "country": "Western Sahara",
            "code": "EH",
            "3code": "ESH"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "British Indian Ocean Territory",
            "code": "IO",
            "3code": "IOT"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Burundi",
            "code": "BI",
            "3code": "BDI"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Comoros",
            "code": "KM",
            "3code": "COM"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Djibouti",
            "code": "DJ",
            "3code": "DJI"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Eritrea",
            "code": "ER",
            "3code": "ERI"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Ethiopia",
            "code": "ET",
            "3code": "ETH"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "French Southern Territories",
            "code": "TF",
            "3code": "ATF"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Kenya",
            "code": "KE",
            "3code": "KEN"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Madagascar",
            "code": "MG",
            "3code": "MDG"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Malawi",
            "code": "MW",
            "3code": "MWI"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Mauritius",
            "code": "MU",
            "3code": "MUS"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Mayotte",
            "code": "YT",
            "3code": "MYT"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Mozambique",
            "code": "MZ",
            "3code": "MOZ"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Réunion",
            "code": "RE",
            "3code": "REU"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Rwanda",
            "code": "RW",
            "3code": "RWA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Seychelles",
            "code": "SC",
            "3code": "SYC"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Somalia",
            "code": "SO",
            "3code": "SOM"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "South Sudan",
            "code": "SS",
            "3code": "SSD"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Uganda",
            "code": "UG",
            "3code": "UGA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "United Republic of Tanzania",
            "code": "TZ",
            "3code": "TZA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Zambia",
            "code": "ZM",
            "3code": "ZMB"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Zimbabwe",
            "code": "ZW",
            "3code": "ZWE"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Angola",
            "code": "AO",
            "3code": "AGO"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Cameroon",
            "code": "CM",
            "3code": "CMR"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Central African Republic",
            "code": "CF",
            "3code": "CAF"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Chad",
            "code": "TD",
            "3code": "TCD"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Congo",
            "code": "CG",
            "3code": "COG"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Democratic Republic of the Congo",
            "code": "CD",
            "3code": "COD"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Equatorial Guinea",
            "code": "GQ",
            "3code": "GNQ"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Gabon",
            "code": "GA",
            "3code": "GAB"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Sao Tome and Principe",
            "code": "ST",
            "3code": "STP"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Botswana",
            "code": "BW",
            "3code": "BWA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Eswatini",
            "code": "SZ",
            "3code": "SWZ"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Lesotho",
            "code": "LS",
            "3code": "LSO"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Namibia",
            "code": "NA",
            "3code": "NAM"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "South Africa",
            "code": "ZA",
            "3code": "ZAF"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Benin",
            "code": "BJ",
            "3code": "BEN"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Burkina Faso",
            "code": "BF",
            "3code": "BFA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Cabo Verde",
            "code": "CV",
            "3code": "CPV"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Côte d’Ivoire",
            "code": "CI",
            "3code": "CIV"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Gambia",
            "code": "GM",
            "3code": "GMB"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Ghana",
            "code": "GH",
            "3code": "GHA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Guinea",
            "code": "GN",
            "3code": "GIN"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Guinea_Bissau",
            "code": "GW",
            "3code": "GNB"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Liberia",
            "code": "LR",
            "3code": "LBR"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Mali",
            "code": "ML",
            "3code": "MLI"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Mauritania",
            "code": "MR",
            "3code": "MRT"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Niger",
            "code": "NE",
            "3code": "NER"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Nigeria",
            "code": "NG",
            "3code": "NGA"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Saint Helena",
            "code": "SH",
            "3code": "SHN"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Senegal",
            "code": "SN",
            "3code": "SEN"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Sierra Leone",
            "code": "SL",
            "3code": "SLE"
        },
        {
            "region": "Africa",
            "sub_region": "Sub_Saharan Africa",
            "country": "Togo",
            "code": "TG",
            "3code": "TGO"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Anguilla",
            "code": "AI",
            "3code": "AIA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Antigua and Barbuda",
            "code": "AG",
            "3code": "ATG"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Aruba",
            "code": "AW",
            "3code": "ABW"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Bahamas",
            "code": "BS",
            "3code": "BHS"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Barbados",
            "code": "BB",
            "3code": "BRB"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Bonaire, Sint Eustatius and Saba",
            "code": "BQ",
            "3code": "BES"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "British Virgin Islands",
            "code": "VG",
            "3code": "VGB"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Cayman Islands",
            "code": "KY",
            "3code": "CYM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Cuba",
            "code": "CU",
            "3code": "CUB"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Curaçao",
            "code": "CW",
            "3code": "CUW"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Dominica",
            "code": "DM",
            "3code": "DMA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Dominican Republic",
            "code": "DO",
            "3code": "DOM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Grenada",
            "code": "GD",
            "3code": "GRD"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Guadeloupe",
            "code": "GP",
            "3code": "GLP"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Haiti",
            "code": "HT",
            "3code": "HTI"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Jamaica",
            "code": "JM",
            "3code": "JAM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Martinique",
            "code": "MQ",
            "3code": "MTQ"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Montserrat",
            "code": "MS",
            "3code": "MSR"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Puerto Rico",
            "code": "PR",
            "3code": "PRI"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Saint Barthélemy",
            "code": "BL",
            "3code": "BLM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Saint Kitts and Nevis",
            "code": "KN",
            "3code": "KNA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Saint Lucia",
            "code": "LC",
            "3code": "LCA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Saint Martin (French Part)",
            "code": "MF",
            "3code": "MAF"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Saint Vincent and the Grenadines",
            "code": "VC",
            "3code": "VCT"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Sint Maarten (Dutch part)",
            "code": "SX",
            "3code": "SXM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Trinidad and Tobago",
            "code": "TT",
            "3code": "TTO"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Turks and Caicos Islands",
            "code": "TC",
            "3code": "TCA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "United States Virgin Islands",
            "code": "VI",
            "3code": "VIR"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Belize",
            "code": "BZ",
            "3code": "BLZ"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Costa Rica",
            "code": "CR",
            "3code": "CRI"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "El Salvador",
            "code": "SV",
            "3code": "SLV"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Guatemala",
            "code": "GT",
            "3code": "GTM"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Honduras",
            "code": "HN",
            "3code": "HND"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Mexico",
            "code": "MX",
            "3code": "MEX"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Nicaragua",
            "code": "NI",
            "3code": "NIC"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Panama",
            "code": "PA",
            "3code": "PAN"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Argentina",
            "code": "AR",
            "3code": "ARG"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Bolivia (Plurinational State of)",
            "code": "BO",
            "3code": "BOL"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Bouvet Island",
            "code": "BV",
            "3code": "BVT"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Brazil",
            "code": "BR",
            "3code": "BRA"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Chile",
            "code": "CL",
            "3code": "CHL"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Colombia",
            "code": "CO",
            "3code": "COL"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Ecuador",
            "code": "EC",
            "3code": "ECU"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Falkland Islands (Malvinas)",
            "code": "FK",
            "3code": "FLK"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "French Guiana",
            "code": "GF",
            "3code": "GUF"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Guyana",
            "code": "GY",
            "3code": "GUY"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Paraguay",
            "code": "PY",
            "3code": "PRY"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Peru",
            "code": "PE",
            "3code": "PER"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "South Georgia and the South Sandwich Islands",
            "code": "GS",
            "3code": "SGS"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Suriname",
            "code": "SR",
            "3code": "SUR"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Uruguay",
            "code": "UY",
            "3code": "URY"
        },
        {
            "region": "Americas",
            "sub_region": "Latin America and the Caribbean",
            "country": "Venezuela (Bolivarian Republic of)",
            "code": "VE",
            "3code": "VEN"
        },
        {
            "region": "Americas",
            "sub_region": "Northern America",
            "country": "Bermuda",
            "code": "BM",
            "3code": "BMU"
        },
        {
            "region": "Americas",
            "sub_region": "Northern America",
            "country": "Canada",
            "code": "CA",
            "3code": "CAN"
        },
        {
            "region": "Americas",
            "sub_region": "Northern America",
            "country": "Greenland",
            "code": "GL",
            "3code": "GRL"
        },
        {
            "region": "Americas",
            "sub_region": "Northern America",
            "country": "Saint Pierre and Miquelon",
            "code": "PM",
            "3code": "SPM"
        },
        {
            "region": "Americas",
            "sub_region": "Northern America",
            "country": "United States of America",
            "code": "US",
            "3code": "USA"
        },
        {
            "region": "",
            "sub_region": "",
            "country": "Antarctica",
            "code": "AQ",
            "3code": "ATA"
        },
        {
            "region": "Asia",
            "sub_region": "Central Asia",
            "country": "Kazakhstan",
            "code": "KZ",
            "3code": "KAZ"
        },
        {
            "region": "Asia",
            "sub_region": "Central Asia",
            "country": "Kyrgyzstan",
            "code": "KG",
            "3code": "KGZ"
        },
        {
            "region": "Asia",
            "sub_region": "Central Asia",
            "country": "Tajikistan",
            "code": "TJ",
            "3code": "TJK"
        },
        {
            "region": "Asia",
            "sub_region": "Central Asia",
            "country": "Turkmenistan",
            "code": "TM",
            "3code": "TKM"
        },
        {
            "region": "Asia",
            "sub_region": "Central Asia",
            "country": "Uzbekistan",
            "code": "UZ",
            "3code": "UZB"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "China",
            "code": "CN",
            "3code": "CHN"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "China, Hong Kong Special Administrative_Region",
            "code": "HK",
            "3code": "HKG"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "China, Macao Special Administrative_Region",
            "code": "MO",
            "3code": "MAC"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "Democratic People's Republic of Korea",
            "code": "KP",
            "3code": "PRK"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "Japan",
            "code": "JP",
            "3code": "JPN"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "Mongolia",
            "code": "MN",
            "3code": "MNG"
        },
        {
            "region": "Asia",
            "sub_region": "Eastern Asia",
            "country": "Republic of Korea",
            "code": "KR",
            "3code": "KOR"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Brunei Darussalam",
            "code": "BN",
            "3code": "BRN"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Cambodia",
            "code": "KH",
            "3code": "KHM"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Indonesia",
            "code": "ID",
            "3code": "IDN"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Lao People's Democratic Republic",
            "code": "LA",
            "3code": "LAO"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Malaysia",
            "code": "MY",
            "3code": "MYS"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Myanmar",
            "code": "MM",
            "3code": "MMR"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Philippines",
            "code": "PH",
            "3code": "PHL"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Singapore",
            "code": "SG",
            "3code": "SGP"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Thailand",
            "code": "TH",
            "3code": "THA"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Timor_Leste",
            "code": "TL",
            "3code": "TLS"
        },
        {
            "region": "Asia",
            "sub_region": "South_eastern Asia",
            "country": "Viet Nam",
            "code": "VN",
            "3code": "VNM"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Afghanistan",
            "code": "AF",
            "3code": "AFG"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Bangladesh",
            "code": "BD",
            "3code": "BGD"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Bhutan",
            "code": "BT",
            "3code": "BTN"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "India",
            "code": "IN",
            "3code": "IND"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Iran (Islamic Republic of)",
            "code": "IR",
            "3code": "IRN"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Maldives",
            "code": "MV",
            "3code": "MDV"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Nepal",
            "code": "NP",
            "3code": "NPL"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Pakistan",
            "code": "PK",
            "3code": "PAK"
        },
        {
            "region": "Asia",
            "sub_region": "Southern Asia",
            "country": "Sri Lanka",
            "code": "LK",
            "3code": "LKA"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Armenia",
            "code": "AM",
            "3code": "ARM"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Azerbaijan",
            "code": "AZ",
            "3code": "AZE"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Bahrain",
            "code": "BH",
            "3code": "BHR"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Cyprus",
            "code": "CY",
            "3code": "CYP"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Georgia",
            "code": "GE",
            "3code": "GEO"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Iraq",
            "code": "IQ",
            "3code": "IRQ"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Israel",
            "code": "IL",
            "3code": "ISR"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Jordan",
            "code": "JO",
            "3code": "JOR"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Kuwait",
            "code": "KW",
            "3code": "KWT"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Lebanon",
            "code": "LB",
            "3code": "LBN"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Oman",
            "code": "OM",
            "3code": "OMN"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Qatar",
            "code": "QA",
            "3code": "QAT"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Saudi Arabia",
            "code": "SA",
            "3code": "SAU"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "State of Palestine",
            "code": "PS",
            "3code": "PSE"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Syrian Arab Republic",
            "code": "SY",
            "3code": "SYR"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Türkiye",
            "code": "TR",
            "3code": "TUR"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "United Arab Emirates",
            "code": "AE",
            "3code": "ARE"
        },
        {
            "region": "Asia",
            "sub_region": "Western Asia",
            "country": "Yemen",
            "code": "YE",
            "3code": "YEM"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Belarus",
            "code": "BY",
            "3code": "BLR"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Bulgaria",
            "code": "BG",
            "3code": "BGR"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Czechia",
            "code": "CZ",
            "3code": "CZE"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Hungary",
            "code": "HU",
            "3code": "HUN"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Poland",
            "code": "PL",
            "3code": "POL"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Republic of Moldova",
            "code": "MD",
            "3code": "MDA"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Romania",
            "code": "RO",
            "3code": "ROU"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Russian Federation",
            "code": "RU",
            "3code": "RUS"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Slovakia",
            "code": "SK",
            "3code": "SVK"
        },
        {
            "region": "Europe",
            "sub_region": "Eastern Europe",
            "country": "Ukraine",
            "code": "UA",
            "3code": "UKR"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Åland Islands",
            "code": "AX",
            "3code": "ALA"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Denmark",
            "code": "DK",
            "3code": "DNK"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Estonia",
            "code": "EE",
            "3code": "EST"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Faroe Islands",
            "code": "FO",
            "3code": "FRO"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Finland",
            "code": "FI",
            "3code": "FIN"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Guernsey",
            "code": "GG",
            "3code": "GGY"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Iceland",
            "code": "IS",
            "3code": "ISL"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Ireland",
            "code": "IE",
            "3code": "IRL"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Isle of Man",
            "code": "IM",
            "3code": "IMN"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Jersey",
            "code": "JE",
            "3code": "JEY"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Latvia",
            "code": "LV",
            "3code": "LVA"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Lithuania",
            "code": "LT",
            "3code": "LTU"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Norway",
            "code": "NO",
            "3code": "NOR"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Svalbard and Jan Mayen Islands",
            "code": "SJ",
            "3code": "SJM"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "Sweden",
            "code": "SE",
            "3code": "SWE"
        },
        {
            "region": "Europe",
            "sub_region": "Northern Europe",
            "country": "United Kingdom of Great Britain and Northern Ireland",
            "code": "GB",
            "3code": "GBR"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Albania",
            "code": "AL",
            "3code": "ALB"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Andorra",
            "code": "AD",
            "3code": "AND"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Bosnia and Herzegovina",
            "code": "BA",
            "3code": "BIH"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Croatia",
            "code": "HR",
            "3code": "HRV"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Gibraltar",
            "code": "GI",
            "3code": "GIB"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Greece",
            "code": "GR",
            "3code": "GRC"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Holy See",
            "code": "VA",
            "3code": "VAT"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Italy",
            "code": "IT",
            "3code": "ITA"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Malta",
            "code": "MT",
            "3code": "MLT"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Montenegro",
            "code": "ME",
            "3code": "MNE"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "North Macedonia",
            "code": "MK",
            "3code": "MKD"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Portugal",
            "code": "PT",
            "3code": "PRT"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "San Marino",
            "code": "SM",
            "3code": "SMR"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Serbia",
            "code": "RS",
            "3code": "SRB"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Slovenia",
            "code": "SI",
            "3code": "SVN"
        },
        {
            "region": "Europe",
            "sub_region": "Southern Europe",
            "country": "Spain",
            "code": "ES",
            "3code": "ESP"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Austria",
            "code": "AT",
            "3code": "AUT"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Belgium",
            "code": "BE",
            "3code": "BEL"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "France",
            "code": "FR",
            "3code": "FRA"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Germany",
            "code": "DE",
            "3code": "DEU"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Liechtenstein",
            "code": "LI",
            "3code": "LIE"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Luxembourg",
            "code": "LU",
            "3code": "LUX"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Monaco",
            "code": "MC",
            "3code": "MCO"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Netherlands (Kingdom of the)",
            "code": "NL",
            "3code": "NLD"
        },
        {
            "region": "Europe",
            "sub_region": "Western Europe",
            "country": "Switzerland",
            "code": "CH",
            "3code": "CHE"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "Australia",
            "code": "AU",
            "3code": "AUS"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "Christmas Island",
            "code": "CX",
            "3code": "CXR"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "Cocos (Keeling) Islands",
            "code": "CC",
            "3code": "CCK"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "Heard Island and McDonald Islands",
            "code": "HM",
            "3code": "HMD"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "New Zealand",
            "code": "NZ",
            "3code": "NZL"
        },
        {
            "region": "Oceania",
            "sub_region": "Australia and New Zealand",
            "country": "Norfolk Island",
            "code": "NF",
            "3code": "NFK"
        },
        {
            "region": "Oceania",
            "sub_region": "Melanesia",
            "country": "Fiji",
            "code": "FJ",
            "3code": "FJI"
        },
        {
            "region": "Oceania",
            "sub_region": "Melanesia",
            "country": "New Caledonia",
            "code": "NC",
            "3code": "NCL"
        },
        {
            "region": "Oceania",
            "sub_region": "Melanesia",
            "country": "Papua New Guinea",
            "code": "PG",
            "3code": "PNG"
        },
        {
            "region": "Oceania",
            "sub_region": "Melanesia",
            "country": "Solomon Islands",
            "code": "SB",
            "3code": "SLB"
        },
        {
            "region": "Oceania",
            "sub_region": "Melanesia",
            "country": "Vanuatu",
            "code": "VU",
            "3code": "VUT"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Guam",
            "code": "GU",
            "3code": "GUM"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Kiribati",
            "code": "KI",
            "3code": "KIR"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Marshall Islands",
            "code": "MH",
            "3code": "MHL"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Micronesia (Federated States of)",
            "code": "FM",
            "3code": "FSM"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Nauru",
            "code": "NR",
            "3code": "NRU"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Northern Mariana Islands",
            "code": "MP",
            "3code": "MNP"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "Palau",
            "code": "PW",
            "3code": "PLW"
        },
        {
            "region": "Oceania",
            "sub_region": "Micronesia",
            "country": "United States Minor Outlying Islands",
            "code": "UM",
            "3code": "UMI"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "American Samoa",
            "code": "AS",
            "3code": "ASM"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Cook Islands",
            "code": "CK",
            "3code": "COK"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "French Polynesia",
            "code": "PF",
            "3code": "PYF"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Niue",
            "code": "NU",
            "3code": "NIU"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Pitcairn",
            "code": "PN",
            "3code": "PCN"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Samoa",
            "code": "WS",
            "3code": "WSM"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Tokelau",
            "code": "TK",
            "3code": "TKL"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Tonga",
            "code": "TO",
            "3code": "TON"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Tuvalu",
            "code": "TV",
            "3code": "TUV"
        },
        {
            "region": "Oceania",
            "sub_region": "Polynesia",
            "country": "Wallis and Futuna Islands",
            "code": "WF",
            "3code": "WLF"
        }
    ]
};
