const authDictionary = {
    en: {
        "Terms": 'I agree to the <a href="http://example.com" target="blank">Terms & Conditions</a>',
        "AgreeTerms": "You must agree to the Terms & Conditions",
    },
    fr: {
        "Given Name": "Prénom",
        "Family Name": "Nom de famille",
        "Language": "Langue",
        "Terms": 'J\'accepte les <a href="http://example.com" target="blank">Conditions Générales</a>',
        "AgreeTerms": "Vous devez accepter les Conditions Générales.",
    },
    de: {
        "Given Name": "Vorname",
        "Family Name": "Nachname",
        "Language": "Sprache",
        "Terms": 'Ich akzeptiere die <a href="http://example.com" target="blank">Allgemeinen Geschäftsbedingungen</a>',
        "AgreeTerms": "Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen.",
    },
    es: {
        "Given Name": "Nombre",
        "Family Name": "Apellido",
        "Language": "Idioma",
        "Terms": 'Acepto los <a href="http://example.com" target="blank">Términos y Condiciones</a>',
        "AgreeTerms": "Debe aceptar los Términos y Condiciones.",
    }
}

export default authDictionary;
