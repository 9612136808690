import React, { useState, useEffect } from 'react';
import { useFormDispatch, useForm } from "../reducers";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { ArrowCircleRight, TaskAlt, NavigateNext, NavigateBefore, Cancel } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { Hub } from "aws-amplify/utils";
import { I18n as i18n } from "aws-amplify/utils";

export default function Navbar() {
    const dispatch = useFormDispatch();
    const { form, pageNumber, numberOfInvalidControls, blocked } = useForm();
    const { pages = [] } = form;
    const { navigation = [] } = pages[pageNumber] || {};
    const theme = useTheme();

    // State to manage blocking of actions until re-render
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        // When pageNumber or blocked changes, reset the isBlocked state
        setIsBlocked(false);
    }, [pageNumber, blocked]);

    if (!navigation) {
        return null;
    }

    const handleChange = (event, newValue) => {
        // Block user actions if re-render is in process
        if (isBlocked) return;

        // Set the state to block further actions until re-render
        setIsBlocked(true);

        // Dispatch actions
        dispatch({ type: "SET_PAGE_NUMBER", value: newValue.newPageNumber });
        if (newValue.action) {
            dispatch({ type: newValue.action, value: newValue.actionValue });
        }
        if (newValue.buttonEvent) {
            Hub.dispatch('events', { event: newValue.buttonEvent });
        }
    };

    return (
        <Box borderTop="1px solid rgba(0, 0, 0, 0.12)">
            <BottomNavigation
                showLabels
                onChange={handleChange}
                sx={{ backgroundColor: "#f0f0f0", padding: "16px" }}
            >
                {navigation.map((nav, index) => {
                    const { icon, buttonColor, newPageNumber, action, buttonEvent, actionValue } = getNavProps(nav, theme, pageNumber, numberOfInvalidControls);
                    const isButtonDisabled = (nav.type === "NEXT" || nav.type === "DONE") && (!!numberOfInvalidControls || blocked);

                    return (
                        <BottomNavigationAction
                            key={index}
                            label={i18n.get(nav.label.toLowerCase())}
                            icon={icon}
                            sx={{
                                color: isButtonDisabled ? "#afafaf" : buttonColor,
                                "&.Mui-disabled": { color: "#afafaf" },
                                "& .MuiSvgIcon-root": { fontSize: "30pt" },
                            }}
                            disabled={isButtonDisabled || isBlocked} // Disable button when blocked
                            value={{ newPageNumber, action, actionValue, buttonEvent }}
                        />
                    );
                })}
            </BottomNavigation>
        </Box>
    );
}

function getNavProps(nav, theme, pageNumber) {
    let icon, buttonColor = "inherit", newPageNumber, action, actionValue, buttonEvent;

    switch (nav.type) {
        case "NEXT":
            icon = <NavigateNext />;
            buttonColor = theme.palette.info.dark;
            newPageNumber = pageNumber + 1;
            buttonEvent = 'next';
            break;
        case "PREVIOUS":
            icon = <NavigateBefore />;
            newPageNumber = pageNumber - 1;
            buttonEvent = 'previous';
            break;
        case "CANCEL":
            icon = <Cancel />;
            buttonColor = theme.palette.error.main;
            action = 'SET_SUBMISSION_ID';
            actionValue = null;
            newPageNumber = 0;
            buttonEvent = 'cancel';
            break;
        case "SUBMIT":
            icon = <ArrowCircleRight />;
            buttonColor = theme.palette.warning.dark;
            newPageNumber = 0;
            buttonEvent = 'submit';
            break;
        case "DONE":
            icon = <TaskAlt />;
            buttonColor = theme.palette.warning.dark;
            newPageNumber = 0;
            buttonEvent = 'complete';
            break;
        default:
            icon = null;
    }

    return { icon, buttonColor, newPageNumber, action, actionValue, buttonEvent };
}
