import React, { useReducer, useContext, createContext } from "react";
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep'; // Import cloneDeep from Lodash

export const FormContext = createContext(null);
const FormDispatchContext = createContext(null);

const initialData = {
    form: {
        dictionaries: null,
        meta: { name: 'Welcome', logo: '' },
        pages: [{
            title: '', navigation: null, header: null, footer: null,
            controls: []
        }]
    },
    submissions: [],
    submissionId: null,
    pageNumber: 0,
    numberOfInvalidControls: 0,
    blocked: false,
    status: [[]],
    user: {},
    language: 'en',
    token: null // Ensure token is part of the initial state
};

const actionHandlers = {
    'SET_USER': (state, value) => {
        if (value !== state.user) {
            return { ...state, user: cloneDeep(value) };
        }
        return state;
    },
    'SET_PAGE_NUMBER': (state, value) => {
        if (value !== state.pageNumber) {
            return { ...state, pageNumber: value };
        }
        return state;
    },
    'SET_NO_OF_INVALID_CONTROLS': (state, value) => {
        if (value !== state.numberOfInvalidControls) {
            return { ...state, numberOfInvalidControls: value };
        }
        return state;
    },
    'SET_BLOCKED': (state, value) => {
        if (value !== state.blocked) {
            return { ...state, blocked: value };
        }
        return state;
    },
    'CHANGE_FORM': (state, value) => {
        if (value !== state.form) {
            return { ...state, form: cloneDeep(value) };
        }
        return state;
    },
    'LOAD_SUBMISSIONS': (state, value) => {
        if (value !== state.submissions) {
            return { ...state, submissions: cloneDeep(value) };
        }
        return state;
    },
    'SET_SUBMISSION_ID': (state, value) => {
        if (value !== state.submissionId) {
            return { ...state, submissionId: value };
        }
        return state;
    },
    'SET_STATUS': (state, value) => {
        if (value !== state.status) {
            return { ...state, status: cloneDeep(value) };
        }
        return state;
    },
    'SET_LANGUAGE': (state, value) => {
        if (value !== state.language) {
            return { ...state, language: value };
        }
        return state;
    },
    'SET_TOKEN': (state, value) => { // Handle SET_TOKEN action
        if (value !== state.token) {
            return { ...state, token: value };
        }
        return state;
    }
};

const formReducer = (state, action) => {
    // console.log('action:', action.type, action.value);
    const handler = actionHandlers[action.type];
    if (handler) {
        return handler(state, action.value);
    } else {
        throw new Error(`Unknown action: ${action.type}, value=${action.value}`);
    }
};

export function FormProvider({ children }) {
    const [form, dispatch] = useReducer(
        formReducer,
        initialData
    );

    // Translate function for UI which has access to uiDictionary
    /*    const translateUI = (key) => {
            const translationKeys = uiDictionary[form.language];
            return translationKeys && translationKeys[key.toLowerCase()] ? translationKeys[key.toLowerCase()] : key;
        };*/

    // Translate function for form, which needs to be supplied with a dictionary
    /*    const translate = (key) => {
            const translationKeys = formDictionary['fr'];
            console.log(translationKeys[key.toLowerCase()] )
            return translationKeys && translationKeys[key.toLowerCase()] ? translationKeys[key.toLowerCase()] : key;
        };*/

    const contextValue = {
        ...form,
        // translateUI,
        // translate,
    };

    return (
        <FormContext.Provider value={contextValue}>
            <FormDispatchContext.Provider value={dispatch}>
                {children}
            </FormDispatchContext.Provider>
        </FormContext.Provider>
    );
}

FormProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export function useForm() {
    return useContext(FormContext);
}

export function useFormDispatch() {
    return useContext(FormDispatchContext);
}
