import React, {useState, useEffect, useMemo, useCallback, useRef} from "react";
import {useForm, useFormDispatch} from "../reducers";
import {useAlert} from "../alertContext";
import parse from "html-react-parser";
import {I18n as i18n} from "aws-amplify/utils";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarterKit from "@tiptap/starter-kit";
import cloneDeep from 'lodash/cloneDeep';
import {get, set, debounce} from 'lodash';
import {
    MenuButtonRedo,
    MenuButtonUndo,
    MenuButtonBold,
    MenuButtonItalic,
    MenuButtonUnderline,
    MenuButtonBulletedList,
    MenuButtonOrderedList,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuControlsContainer,
    MenuDivider,
    RichTextEditor,
} from "mui-tiptap";
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import {
    Select,
    Radio,
    RadioGroup,
    TextareaAutosize,
    TextField,
    useTheme,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    FormGroup,
    Box,
    Card,
    CardContent,
    Fab,
    Zoom,
    Badge
} from "@mui/material";

import {
    countryCodes
} from "../assets/countryCodes";
import {DataStore} from "@aws-amplify/datastore";
import {Submissions} from "../models";
import SubmissionList from "../controls/submissionList";
import Summary from "../controls/summary";
import Authors from "../controls/authors";
import FileUpload from "../controls/fileUpload";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VerifiedIcon from "@mui/icons-material/Verified";
import AsteriskIcon from "../assets/asterisk"
import {DateCalendar} from "@mui/x-date-pickers";
import Maths from "../controls/maths";
import ActionButton from "../controls/actionButton";

export default function Main() {
    //TODO - obtain user language
    const UIlanguage = "en-gb";
    const theme = useTheme();
    const dispatch = useFormDispatch();
    const isEditing = useRef(false);

    const {form, submissions, submissionId, pageNumber, language} = useForm();
    const page = form.pages[pageNumber];

    // Deep clone the initial submission to prevent mutations
    const initialSubmission = submissions?.find((item) => item.id === submissionId) || null;
    const [submission, setSubmission] = useState(initialSubmission ? cloneDeep(initialSubmission) : null);

    const {triggerAlert} = useAlert();

    const controls = useMemo(() => {
        return page.controls || [];
    }, [page.controls]);

    /*
      -----------------------------------
      Scroll position
      */

    // handle scrolling back to top...
    function throttle(func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall > delay) {
                lastCall = now;
                func(...args);
            }
        };
    }

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = useMemo(() => throttle(() => {
        const position = document.getElementById("main-content").scrollTop;
        setScrollPosition((currentPosition) => currentPosition !== position ? position : currentPosition);
    }, 100), [setScrollPosition]);

    useEffect(() => {
        const contentContainer = document.getElementById("main-content");
        if (contentContainer) {
            contentContainer.addEventListener("scroll", handleScroll);

            // Cleanup function to remove the event listener
            return () => contentContainer.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]); // handleScroll is stable thanks to useCallback, so it won't cause the effect to rerun unnecessarily

    const handleScrollTopClick = (behavior) => {
        const anchor = document.getElementById("back-to-top-anchor");
        if (anchor) {
            // Conditionally set the behavior option based on the provided argument
            const scrollOptions = {block: "start"};
            if (behavior === "smooth") {
                scrollOptions.behavior = "smooth";
            }
            anchor.scrollIntoView(scrollOptions);
        }
    };

    // detect page change
    useEffect(() => {
        isEditing.current = false;
        handleScrollTopClick("instant");
    }, [pageNumber]);

    /*
      -----------------------------------
      Translations
      */

    useEffect(() => {
        i18n.setLanguage(language || 'en');
    }, [language])

    useEffect(() => {
        if (submission) {
            if (submission.meta.submissionLanguage !== language) {
                // Deep copy submission before updating
                const updatedSubmission = cloneDeep(submission);
                updatedSubmission.meta.submissionLanguage = language;
                DataStore.save(Submissions.copyOf(updatedSubmission, (updated) => {
                    updatedSubmission.token = "defaultValue";
                    updated.meta.submissionLanguage = language;
                }));
            }
        }
    }, [submission, language]);

    /*
      -----------------------------------
      Lists
      */
    const countryList = useMemo(() => {
        const list = countryCodes[UIlanguage]?.sort((a, b) => a.Country > b.Country ? 1 : -1) || [];
        return list.map((language) => ({
            name: language.Country, value: language.Country,
        }));
    }, [UIlanguage]);

    /*
      ----------------------------------------------------------------------
      Values and validation
      */

    // Initial state
    const [controlStates, setControlStates] = useState({});

    useEffect(() => {
        if (controlStates) {
            const numberOfInvalidControls = controls.reduce((count, control) => {
                const state = controlStates[control.key];
                if (state && !state.isValid && !state.isHidden) {
                    count++;
                }

                return count;
            }, 0);

            dispatch({
                type: "SET_NO_OF_INVALID_CONTROLS", value: numberOfInvalidControls,
            });

            if (numberOfInvalidControls) {
                triggerAlert({
                    message: `${numberOfInvalidControls} required questions not answered`,
                    severity: "error",
                    vertical: "bottom",
                    horizontal: "center",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlStates, pageNumber]);

    function calculatePercentComplete(submission, form) {
        let requiredCount = 0;
        let totalInvalidControls = 0;

        form.pages.forEach(page => {
            // Calculate required count excluding hidden controls
            requiredCount += page.controls.filter(control => control.required && !controlStates[control.key]?.isHidden).length;

            // Iterate over each control to count invalid entries, excluding hidden controls
            page.controls.forEach(control => {
                if (control.required && !controlStates[control.key]?.isHidden) {
                    // Check if there is a valid entry in the submission data
                    let value = get(submission?.data, control.key);
                    if (value === null || value === undefined || value === "") {
                        totalInvalidControls++;
                    }
                }
            });
        });

        // Calculate and return the percentage of completed controls
        if (requiredCount === 0) {
            return 100; // Return 100% if there are no required controls
        } else {
            return Math.round((requiredCount - totalInvalidControls) / requiredCount * 100);
        }
    }


    // Initialize control states with form values from submission
    const controlStatesRef = useRef(controlStates);

    useEffect(() => {
        controlStatesRef.current = controlStates;
    }, [controlStates]);

    const handleSubmissionChange = useCallback(async (submissionObj) => {
        // console.log('handleSubmissionChange called with:', submissionObj);
        if (!submissionObj) return;

        // Deep copy the data to prevent mutations
        const data = cloneDeep(submissionObj.data || {});
        let shouldUpdate = false;

        form.pages.forEach(page => {
            page.controls.forEach(control => {
                const existingValue = get(data, control.key, "");
                if (controlStatesRef.current[control.key]?.value !== existingValue) {
                    shouldUpdate = true;
                }
            });
        });

        if (!shouldUpdate) return; // No need to update controlStates

        let initialStates = {};
        form.pages.forEach(page => {
            page.controls.forEach(control => {
                const existingValue = get(data, control.key, "");

                let maxWords = null;
                try {
                    maxWords = JSON.parse(control.attributes).maxWords;
                } catch (e) {
                    // Handle error or assign default maxWords
                }

                if (control.key) {
                    let words = [];
                    if (typeof existingValue === "string") {
                        words = existingValue.match(/\S+/g) || []; // Match non-space characters to count words
                    }
                    const wordCount = words.length;

                    // Create the state object for each control
                    set(initialStates, control.key, {
                        value: existingValue,
                        isValid: isValidValue(existingValue, !!control.required, JSON.parse(control?.attributes), wordCount),
                        isHidden: false,
                        required: !!control.required,
                        count: wordCount,
                        maxWords: maxWords,
                        hover: -1,
                    });
                }
            });
        });
        // console.log('Initial States set:', initialStates);
        // Deep copy initialStates before modifying
        let newState = cloneDeep(initialStates);

        // Implement show/hide logic using newState
        form.pages.forEach(page => {
            page.controls.forEach(control => {
                const attributes = JSON.parse(control?.attributes || "{}");

                if (attributes?.actions) {
                    attributes.actions.forEach(action => {
                        const controlValue = get(newState, `${control.key}.value`);

                        switch (action.operation) {
                            case "show":
                                switch (action.predicate) {
                                    case "eq":
                                        if (controlValue === action.value) {
                                            action.fields.forEach(field => {
                                                if (newState[field]) {
                                                    set(newState, `${field}.isHidden`, false);
                                                }
                                            });
                                        }
                                        break;
                                    case "ne":
                                        if (controlValue !== action.value) {
                                            action.fields.forEach(field => {
                                                if (newState[field]) {
                                                    set(newState, `${field}.isHidden`, false);
                                                }
                                            });
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            case "hide":
                                switch (action.predicate) {
                                    case "eq":
                                        if (controlValue === action.value) {
                                            action.fields.forEach(field => {
                                                if (newState[field]) {
                                                    set(newState, `${field}.isHidden`, true);
                                                }
                                            });
                                        }
                                        break;
                                    case "ne":
                                        if (controlValue !== action.value) {
                                            action.fields.forEach(field => {
                                                if (newState[field]) {
                                                    set(newState, `${field}.isHidden`, true);
                                                }
                                            });
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            case "block":
                                switch (action.predicate) {
                                    case "eq":
                                        dispatch({
                                            type: 'SET_BLOCKED',
                                            value: controlValue === action.value,
                                        });
                                        break;
                                    case "ne":
                                        dispatch({
                                            type: 'SET_BLOCKED',
                                            value: controlValue !== action.value,
                                        });
                                        break;
                                    default:
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                    });
                }
            });
        });

        // Finally, set the controlStates with the new state
        console.log(newState);
        setControlStates(newState);
    }, [form.pages, dispatch]);

    useEffect(() => {
        // console.log('useEffect triggered: submissionId changed to', submissionId);
        if (submissionId && submissions?.length > 0) {
            const initialSubmission = submissions.find((item) => item.id === submissionId);
            if (initialSubmission) {
                // Ensure a deep clone here
                setSubmission(cloneDeep(initialSubmission));
                handleSubmissionChange(initialSubmission);
            }
        } else {
            setSubmission(null);
            setControlStates({});
        }
        isEditing.current = false; // Reset the flag after handling
        // -eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionId, submissions, handleSubmissionChange]);

    function isValidValue(value, required, attributes, count) {
        // Check if the value is not undefined or null
        const hasValue = typeof value === 'boolean' ? true : value !== undefined && value !== null && value !== "";

        // Initialize isValid based on whether the field is required and has a value
        let isValid = !required || hasValue;

        if (attributes?.validity) {
            // If there's a validity requirement, adjust isValid accordingly
            switch (attributes.validity?.predicate) {
                case "eq":
                    isValid = isValid && (value === attributes.validity?.value);
                    break;
                default:
                    break;
            }
        }

        // Check word count if applicable
        const maxWords = attributes?.maxWords ?? 0;
        if (maxWords > 0 && typeof count === 'number') {
            if (count > maxWords) {
                isValid = false;
            }
        }

        return isValid;
    }

    useEffect(() => {
        if (submission) {

            // Debounce save function to prevent rapid submissions
            const debouncedSave = debounce(() => {
                // Deep copy the submission data
                const dataCopy = cloneDeep(submission.data || {});
                Object.keys(controlStates).forEach((key) => {
                    set(dataCopy, key, cloneDeep(controlStates[key].value)); // Ensure deep copy of each value
                });
                DataStore.save(Submissions.copyOf(submission, (updatedSubmission) => {
                    updatedSubmission.data = dataCopy;
                    updatedSubmission.token = "defaultValue";
                    updatedSubmission.meta.page = pageNumber;
                    updatedSubmission.meta.percentComplete = calculatePercentComplete(updatedSubmission, form);
                }))
                    .catch((error) => {
                        triggerAlert({
                            message: `Update failed - ${error.message}`,
                            severity: "error",
                        });
                        console.error(`Failed to update: ${error.message}`);
                    });
            }, 2000); // Reduced debounce delay to 500ms

            debouncedSave();

            // Cleanup function to cancel debounce on unmount or before next call
            return () => debouncedSave.cancel();
        }
    }, [controlStates, submission, pageNumber, form, triggerAlert]);

    async function handleHoverChange(key, value) {
        setControlStates(prevStates => {

            // Deep copy of the previous state
            let newState = cloneDeep(prevStates);

            // Update the hover value
            newState[key].hover = value;
            return newState;
        });
    }

    async function handleInputChange(key, value, save = true) { // Added default value for 'save'
        //TODO - state history, including timestamp
        console.log('handleInputChange', key, value)
        isEditing.current = true;

        // Initialize variables
        let count = 0;
        // let newValue = value;

        // get control and attributes
        const control = controls.find(control => control.key === key);
        const attributes = JSON.parse(control?.attributes || "{}")

        // Check if value is a string before performing string operations
        if (typeof value === "string") {
            // Calculate word count
            const words = value.trim().split(/\s+/).filter(Boolean);
            count = words.length;

            // const limit = controlStates[key]?.maxWords ?? 0;

            /* if (save && limit && count > limit) {
                 // Enforce word limit on blur by truncating the text
                 const truncatedWords = words.slice(0, limit);
                 newValue = truncatedWords.join(' ');
                 count = limit; // Update count to reflect the truncated value
             }*/
        } else {
            // For non-string values, word count is not applicable
            count = null; // Or set to 0 or appropriate value
        }

        setControlStates(prevStates => {

            // Deep copy of the previous state
            let newState = cloneDeep(prevStates);

            // Update the specific control state
            if (!newState[key]) {
                newState[key] = {
                    value: null,
                    isValid: false,
                    isHidden: false,
                    required: false,
                    count: 0,
                    maxWords: null,
                    hover: -1,
                };
            }

            newState[key] = {
                ...newState[key],
                value: cloneDeep(value), // Ensure deep copy
                isValid: isValidValue(value, !!newState[key]?.required, controlStates[key], count),
                count: count,
                hover: newState[key]?.hover ?? -1,
            };

            // check for show/hide and other actions
            if (attributes?.actions) {
                attributes.actions.forEach(action => {
                    const setFieldVisibility = (isHidden) => {
                        action.fields.forEach(field => {
                            if (newState[field]) {
                                newState[field].isHidden = isHidden;
                            }
                        });
                    };

                    if (action.operation === "show") {
                        // Implement 'show' logic if needed
                    } else if (action.operation === "hide") {
                        const isEqual = value === action.value;
                        const shouldHide = (action.predicate === "eq" && isEqual) || (action.predicate === "ne" && !isEqual);
                        setFieldVisibility(shouldHide);
                    }
                    if (action.operation === "block") {
                        const isEqual = value === action.value;
                        dispatch({
                            type: 'SET_BLOCKED',
                            value: action.predicate === "eq" && isEqual
                        });
                    }
                })
            }
            return newState;
        });
        isEditing.current = false;
    }

    async function handleFileList(files) {
        const deduplicateFiles = (files) => {
            const fileMap = new Map();
            files.forEach(file => {
                fileMap.set(file.id, file); // Use file.id for deduplication
            });
            return Array.from(fileMap.values());
        };

        // Create a mutable copy of the current submission's data
        const latestSubmissionData = cloneDeep(submission.data) || {};

        // Update the files in the submission
        const updatedFiles = deduplicateFiles(files);

        // Create sets of dataKeys for current and previous files
        const currentDataKeys = new Set(files.map(file => file.dataKey));
        const previousDataKeys = new Set((submission.files || []).map(file => file.dataKey));

        // Update the data copy
        // Handle added or updated files
        currentDataKeys.forEach(dataKey => {
            latestSubmissionData[dataKey] = true;
        });

        // Handle removed files
        previousDataKeys.forEach(dataKey => {
            if (!currentDataKeys.has(dataKey)) {
                latestSubmissionData[dataKey] = false;
            }
        });

        // Update controlStates
        setControlStates(prevStates => {
            const newControlStates = {...prevStates};

            // Handle added or updated files
            currentDataKeys.forEach(dataKey => {
                newControlStates[dataKey] = {
                    ...newControlStates[dataKey],
                    value: true,
                    isValid: true, // Adjust based on your validation logic
                };
            });

            // Handle removed files
            previousDataKeys.forEach(dataKey => {
                if (!currentDataKeys.has(dataKey)) {
                    newControlStates[dataKey] = {
                        ...newControlStates[dataKey],
                        value: false,
                        isValid: false, // Adjust based on your validation logic
                    };
                }
            });

            return newControlStates;
        });

        // Save the updated submission
        await DataStore.save(Submissions.copyOf(submission, (updatedSubmission) => {
            updatedSubmission.files = updatedFiles;
            updatedSubmission.data = latestSubmissionData;
            // Update other properties if necessary
            updatedSubmission.meta = submission.meta; // Include if necessary
            updatedSubmission.token = "defaultValue"; // Include if necessary
        }))
            .then((r) => {
                setSubmission(cloneDeep(r)); // Update the submission state with the latest data
            })
            .catch((error) => {
                triggerAlert({
                    message: `Update failed - ${error.message}`,
                    severity: "error",
                });
                console.error(`Failed to update: ${error.message}`);
            });
    }

    function generateField(control, index, handleInputChange, controlStates, submission) {
        const attributes = JSON.parse(control?.attributes || "{}");
        const key = control.key;
        if (controlStates[key]?.isHidden) {
            return null;
        }
        let label
        try {
            label = control.label ? parse(i18n.get(key)) : null;
        } catch (e) {
            console.log(control, e)
        }

        let elements;

        switch (control.type) {
            case "AFFILIATIONS":
                break;

            case "AUTHORS":
                return <>
                    <div>{parse(i18n.get(control.label || ''))}</div>
                    <Authors data={{
                        authors: controlStates["authors"]?.value || [],
                        affiliations: controlStates["affiliations"]?.value || []
                    }} control={control} currentKey={index} handleInputChange={handleInputChange}/>
                </>;

            case "BUTTON":
                // return <div key={index}>{parse(i18n.get(control.content))}</div>;
                return <ActionButton control={control} submission={cloneDeep(submission)}/>; // Deep clone submission if needed

            case "CHECKBOX":
                elements = [];

                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);

                    let valueToCompare = option.value === "true" ? true : option.value === "false" ? false : option.value;

                    switch (typeof valueToCompare) {
                        case "object":
                            valueToCompare = option.value.fee;
                            break;
                        case "string":
                            valueToCompare = isNaN(Number(valueToCompare)) ? valueToCompare : Number(valueToCompare)
                            break;
                        case "boolean":
                            break;
                        default:
                            //number
                            break;
                    }

                    elements.push(
                        <div key={index + "-" + i}>
                            <Checkbox
                                name={key}
                                checked={controlStates[key]?.value === valueToCompare} // Handle both single and multiple selections
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        // Add the selected value
                                        handleInputChange(key, cloneDeep(valueToCompare)); // Ensure deep copy
                                    } else {
                                        // Remove the selected value
                                        handleInputChange(key, undefined); // Handle single selection case (clear value)
                                    }
                                }}
                            />
                            <label>{parse(i18n.get(option.name))}</label>
                        </div>
                    );
                });

                return (
                    <>
                        {label && <FormLabel>{label}</FormLabel>}
                        <FormGroup>{elements}</FormGroup>
                    </>
                );


            case "DATETIME":
                //TODO Memoize Dynamic Values to avoid re-renders, possibly memoize the onChange Handler

                // Retrieve the raw value from controlStates or default to '2000-01-01'
                const rawValue = controlStates[key]?.value || '2000-01-01';

                // Ensure the value is a Dayjs object
                const value = dayjs.isDayjs(rawValue) ? rawValue : dayjs(rawValue);

                // Validate the date or default to '2000-01-01' if invalid
                const isValidDate = value.isValid() ? value : dayjs('2000-01-01');

                // Determine if future dates should be disabled
                const disableFuture = attributes?.disableFuture === true;

                // Conditionally set maxDate to today if disableFuture is true; otherwise, use control attributes or leave it undefined
                const maxSelectableDate = disableFuture
                    ? dayjs()
                    : attributes?.maxDate
                        ? dayjs(attributes.maxDate)
                        : undefined;

                // Define the minimum selectable date (control.attributes.minDate or January 1, 1920)
                const minSelectableDate = attributes?.minDate
                    ? dayjs(attributes.minDate)
                    : dayjs('1920-01-01');

                return (
                    <>
                        <div>{parse(i18n.get(control.key))}</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                sx={{
                                    transform: 'scale(0.95)',
                                    transformOrigin: 'top left',
                                    marginLeft: 0,
                                    position: 'relative',
                                    marginBottom: 0
                                }}
                                value={isValidDate}
                                minDate={minSelectableDate}
                                maxDate={maxSelectableDate}
                                onChange={(newValue) => {
                                    const parsedValue = dayjs(newValue);
                                    if (parsedValue.isValid()) {
                                        handleInputChange(key, parsedValue.format('YYYY-MM-DD'));
                                    } else {
                                        handleInputChange(key, '2000-01-01');
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </>
                );

            case "FILE": {
                const {key: dataKey, ...otherProps} = control;
                return (
                    <FileUpload
                        dataKey={dataKey}
                        handleFileList={handleFileList}
                        {...otherProps}
                    />);
            }

            case "INFORMATION":
                return <div key={index}>{parse(i18n.get(control.content))}</div>;

            case "LANGUAGES":
                const options = form.meta.formLanguages; // Assuming this is an array like ['en', 'fr', 'pt']
                return (<>
                    <div key={index}>{parse(i18n.get(control.content))}</div>
                    <Select
                        sx={{width: 200, height: 30}}
                        autoWidth
                        renderValue={(language) => i18n.get(language)} // Directly translate the selected language code
                        name={key}
                        value={language || 'en'}
                        onChange={(e) => {
                            dispatch({type: 'SET_LANGUAGE', value: e.target.value});
                            console.log(key);  // Update control state here as needed
                        }}
                    >
                        {options.map((option, i) => (<MenuItem key={`${index}-${i}`} value={option}>
                            {i18n.get(option)} {/* Translate each option */}
                        </MenuItem>))}
                    </Select>
                </>);

            case "MESSAGE": {
                return null;
            }

            case "RADIO":
                elements = [];
                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);
                    // Convert the value to a boolean if it's a boolean-like string
                    let value = option.value;
                    if (value === "true") value = true;
                    if (value === "false") value = false;
                    if (value === "null") value = null;
                    if (value === "undefined") value = undefined;

                    elements.push(<div key={index + "-" + i}>
                        <Radio
                            name={key}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue === "true") newValue = true;
                                if (newValue === "false") newValue = false;
                                handleInputChange(key, cloneDeep(newValue)); // Ensure deep copy
                            }}
                            value={option.value}
                            checked={option.value === controlStates[key]?.value}
                        ></Radio>
                        <label>{parse(i18n.get(option.name))}</label>
                    </div>);
                });
                return (<>
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup>{elements}</RadioGroup>
                </>);

            case "RATING": {

                const options = control.options.map(o => JSON.parse(o));
                options.unshift({}); // add empty object in position 0

                return (<>
                        <div key={index}>{parse(i18n.get(control.label))}</div>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Rating
                                name="hover-feedback"
                                value={parseFloat(controlStates[key]?.value) || 0}  // Convert to number, default to 0 if undefined
                                precision={1}
                                max={control.options.length}
                                // getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                    handleInputChange(key, cloneDeep(newValue)) // Ensure deep copy
                                }}
                                onChangeActive={(event, newHover) => {
                                    handleHoverChange(key, newHover);
                                }}
                                emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
                            />
                            {(
                                <Box sx={{ml: 2}}>
                                    {i18n.get(options[controlStates[key]?.hover !== -1 ? controlStates[key]?.hover : controlStates[key]?.value]?.name)}
                                </Box>
                            )}

                        </Box>
                    </>
                );
            }
            case "SELECT": {
                // TODO: Turn this into a custom component

                let options;
                if (control.options.length === 1) {
                    options = countryList; // Array[JSON.parse(control.options[0])]
                } else {
                    options = control.options.map((item) => JSON.parse(item));
                }

                let value = controlStates[key]?.value;
                if (attributes.multiple) {
                    value = value ? value.split(";") : [];
                } else {
                    value = value ? value : "";
                }

                elements = [];
                options.forEach((option, i) => {
                    let isSelected;
                    if (attributes.multiple) {
                        isSelected = controlStates[key]?.value
                            ? controlStates[key].value.split(";").includes(
                                typeof option.value === "object" ? String(option.value.fee) : String(option.value)
                            ) // Check if value is an object and use fee, otherwise use value
                            : false;
                    } else {
                        isSelected = controlStates[key]?.value ===
                            (typeof option.value === "object" ? String(option.value.fee) : String(option.value)); // Check if value is an object and use fee, otherwise use value
                    }

                    // Conditionally render MenuItem with a checkbox only if attributes.multiple is true
                    const menuItemContent = attributes.multiple ? (
                        <FormControlLabel
                            control={<Checkbox checked={isSelected}/>}
                            label={i18n.get(option.name)}
                            sx={{width: "100%"}}
                        />
                    ) : (
                        i18n.get(option.name)
                    ); // When not multiple, just render the option name

                    elements.push(
                        <MenuItem key={`${index}-${i}`}
                                  value={typeof option.value === 'object' ? option.value.fee : option.value}> {/* Use option.value or option.value.fee */}
                            {menuItemContent}
                        </MenuItem>
                    );
                });

                return (
                    <>
                        <FormLabel>{label}</FormLabel>
                        <Select
                            sx={{width: 200, height: 30}}
                            multiple={attributes.multiple}
                            autoWidth
                            renderValue={(selected) => {
                                if (Array.isArray(selected)) {
                                    return selected.map((sel) => {
                                        const selectedOption = options.find((option) =>
                                            typeof option.value === 'object' ? option.value.fee === sel : option.value === sel
                                        );
                                        return selectedOption ? i18n.get(selectedOption.name) : sel;
                                    }).join("; ");
                                } else {
                                    const selectedOption = options.find((option) =>
                                        typeof option.value === 'object' ? option.value.fee === selected : option.value === selected
                                    );
                                    return selectedOption ? i18n.get(selectedOption.name) : "";
                                }
                            }}
                            value={attributes.multiple ? cloneDeep(value) : cloneDeep(value)} // Ensure deep copy
                            name={key}
                            onChange={(e) => {
                                if (attributes.multiple) {
                                    handleInputChange(key, cloneDeep(e.target.value.join(";")), false); // Ensure deep copy
                                } else {
                                    handleInputChange(key, cloneDeep(e.target.value), false); // Ensure deep copy
                                }
                            }}
                            onBlur={(e) => {
                                if (attributes.multiple) {
                                    handleInputChange(key, cloneDeep(e.target.value.join(";")), true); // Ensure deep copy
                                } else {
                                    handleInputChange(key, cloneDeep(e.target.value), true); // Ensure deep copy
                                }
                            }}
                        >
                            {elements}
                        </Select>
                    </>
                );
            }

            case "MATHS":
                return (
                    <Maths
                        key={index}
                        control={control}
                        submission={cloneDeep(submission)} // Ensure deep copy if Maths component modifies submission
                        handleInputChange={handleInputChange}
                        controlStates={cloneDeep(controlStates)} // Ensure deep copy if Maths component modifies controlStates
                    />
                );

            case "SLIDER":
                //TODO this is a placeholder for the MATHS control type

                return <Maths control={control} submission={cloneDeep(submission)}
                              handleInputChange={handleInputChange}/>; // Ensure deep copy

            case "SPONSOR":
                break;

            case "SUBMISSIONLIST":
                return <SubmissionList control={control} currentKey={index}
                                       handleSubmissionChange={handleSubmissionChange}/>;

            case "SUMMARY":
                return <Summary control={control}/>;

            case "SWITCH":
                break;

            case "TABLE":
                break;

            case "TEXT": {
                if (attributes?.height >= 1) {
                    if (attributes?.rtf) {
                        const rteRef = React.createRef(RichTextEditor)
                        return (<>
                            <FormLabel htmlFor={"textarea" + index}>{label}</FormLabel>
                            <Box
                                sx={{
                                    "&& .ProseMirror": {
                                        height: `${attributes.height * 12 || 100}px`,
                                        overflowY: "auto",
                                    },
                                }}
                            >
                                <RichTextEditor
                                    ref={rteRef}
                                    extensions={[StarterKit, Superscript, Subscript, Underline]} // Or any Tiptap extensions you wish!
                                    content={controlStates[key]?.value || ""} // Initial content for the editor
                                    onBlur={() => handleInputChange(key, rteRef.current?.editor?.getHTML())}
                                    onUpdate={(e) => handleInputChange(key, rteRef.current?.editor?.getHTML(), false)}
                                    renderControls={() => (
                                        <MenuControlsContainer>
                                            {/*<MenuSelectHeading />*/}
                                            <MenuButtonRedo/>
                                            <MenuButtonUndo/>
                                            <MenuDivider/>
                                            <MenuButtonBold/>
                                            <MenuButtonItalic/>
                                            <MenuButtonUnderline/>
                                            <MenuDivider/>
                                            <MenuButtonBulletedList/>
                                            <MenuButtonOrderedList/>
                                            <MenuDivider/>
                                            <MenuButtonSuperscript/>
                                            <MenuButtonSubscript/>
                                        </MenuControlsContainer>
                                    )}
                                /></Box>
                            {attributes?.maxWords > 0 && (
                                <div
                                    style={{color: controlStates[key]?.count > attributes.maxWords ? 'red' : 'inherit'}}>
                                    {controlStates[key]?.count} {i18n.get('words')} (<em>{attributes.maxWords} {i18n.get('limit')}</em>)
                                </div>
                            )}
                        </>)
                    } else {
                        return (<>
                            <FormLabel htmlFor={"textarea" + index}>{label}</FormLabel>
                            <TextareaAutosize
                                key={index}
                                id={"textarea" + index}
                                color="primary"
                                minRows={attributes.height}
                                name={key}
                                onBlur={(e) => handleInputChange(key, e.target.value)}
                                onChange={(e) => handleInputChange(key, e.target.value, false)}
                                value={controlStates[key]?.value || ""}
                                placeholder={control.options?.placeholder}
                            />
                            {attributes?.maxWords > 0 && (
                                <div
                                    style={{color: controlStates[key]?.count > attributes.maxWords ? 'red' : 'inherit'}}>
                                    {controlStates[key]?.count} {i18n.get('words')} (<em>{attributes.maxWords} {i18n.get('limit')}</em>)
                                </div>
                            )}
                        </>)
                    }
                } else {
                    return (<TextField
                        key={index}
                        label={label}
                        color="primary"
                        variant="outlined"
                        name={key}
                        onBlur={(e) => handleInputChange(key, cloneDeep(e.target.value))} // Ensure deep copy
                        onChange={(e) => handleInputChange(key, cloneDeep(e.target.value), false)} // Ensure deep copy
                        value={controlStates[key]?.value || ""}
                        placeholder={control.options?.placeholder}
                    />);
                }
            }

            case "TOGGLE":
                break;
            default:
                return <div key={index}>{control.type}</div>;
        }
    }

    return (<Box
        id="main-content"
        sx={{
            overflowY: "auto", flexGrow: 1, padding: "20px",
        }}
    >
        <div id="back-to-top-anchor"></div>
        <div style={theme.typography.h6}>{parse(i18n.get(page?.title || ''))}</div>
        {controls.map((control, index) => {
            // eslint-disable-next-line no-eval
            if (eval(control?.hidden)) {
                return null;
            }
            const key = control.key;
            let field;
            if (controlStates) {
                field = generateField(control, index, handleInputChange, controlStates, cloneDeep(submission)) // Ensure deep clone if needed
            }

            return (
                <>
                    {field &&
                        <Box
                            style={{display: controlStates[key]?.isHidden ? 'none' : 'block'}}
                            key={`${index}-box`} // Use a unique key
                            sx={{width: "100vw - 20px", padding: "5px 10px 5px 10px"}}
                        >
                            {!!controlStates?.[key] ? (<Badge
                                invisible={!controlStates[key]?.isValid && !control.required} // Show badge only if controlStates[key]?.isValid is true or if control is required and not valid
                                badgeContent={!!control.required && !controlStates[key]?.isValid ? (<AsteriskIcon
                                    style={{color: "red"}}/>) : ((!!control.required && controlStates[key]?.isValid) || !!controlStates[key]?.value ? (
                                    <VerifiedIcon style={{color: "green"}}/>) : null)}

                                style={{width: "100%"}}
                                sx={{
                                    ".MuiBadge-badge": {
                                        transform: "translate(5px, 5px)", // Adjusts the badge position 5px right and 5px down
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: "top", horizontal: "right",
                                }}
                            >
                                <Card
                                    sx={{width: "100%"}}
                                    key={index + "-card"}
                                    variant={"outlined"}
                                >
                                    <CardContent
                                        key={index + "-content"}
                                        sx={{position: "relative"}}
                                    >
                                        <FormControl fullWidth key={index}>
                                            {field}
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Badge>) : (<FormControl fullWidth key={index}>
                                {field}
                            </FormControl>)}
                        </Box>}
                </>);
        })}
        <ScrollTop
            scrollPosition={scrollPosition}
            onScrollTopClick={() => handleScrollTopClick("smooth")}
        />
    </Box>);
}

const ScrollTop = ({scrollPosition, onScrollTopClick}) => {
    const trigger = scrollPosition > 100;
    return (<Zoom in={trigger}>
        <Box
            onClick={onScrollTopClick}
            role="presentation"
            sx={{position: "fixed", bottom: 100, right: 16}}
        >
            <Fab size="small" aria-label="scroll back to top" color="secondary">
                <KeyboardArrowUpIcon/>
            </Fab>
        </Box>
    </Zoom>);
};
