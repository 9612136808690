import React, { useState, useRef, useEffect } from 'react';
import {
    Button, Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Stack,
    TextField
} from '@mui/material';
import parse from "html-react-parser";
import CloseIcon from '@mui/icons-material/Close';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

export default function Authors(props) {
    const updatePendingRef = useRef(false);
    const [open, setOpen] = useState(false);
    const [authors, setAuthors] = useState(props.data.authors || []);
    const [affiliations, setAffiliations] = useState(props.data.affiliations || []);
    const [editingAuthorIndex, setEditingAuthorIndex] = useState(null);
    const [currentAuthor, setCurrentAuthor] = useState({
        givenName: '',
        familyName: '',
        presenting: false,
        affiliations: []
    });
    const [currentAffiliation, setCurrentAffiliation] = useState({ institution: '', country: '' });

    const handleOpen = (index = null) => {
        setEditingAuthorIndex(index);
        if (index !== null) {
            const author = authors[index];
            setCurrentAuthor(author);
            if (author.affiliations.length > 0) {
                setCurrentAffiliation(affiliations[author.affiliations[0]]);
            }
        } else {
            setCurrentAuthor({ givenName: '', familyName: '', presenting: false, affiliations: [] });
            setCurrentAffiliation({ institution: '', country: '' });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAuthorChange = (e) => {
        setCurrentAuthor({ ...currentAuthor, [e.target.name]: e.target.value });
    };

    const handleAffiliationChange = (e) => {
        setCurrentAffiliation({ ...currentAffiliation, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = () => {
        setCurrentAuthor(prevAuthor => ({
            ...prevAuthor,
            presenting: !prevAuthor.presenting
        }));
    };

    const saveAuthorAndAffiliation = () => {
        let newAffiliations = [...affiliations];
        let affiliationIndex = -1;

        if (currentAffiliation.institution.trim() !== '' && currentAffiliation.country.trim() !== '') {
            affiliationIndex = affiliations.findIndex(a => a.institution === currentAffiliation.institution && a.country === currentAffiliation.country);

            if (affiliationIndex === -1) {
                newAffiliations = [...affiliations, currentAffiliation];
                affiliationIndex = newAffiliations.length - 1;
            }
        }

        const updatedAuthor = { ...currentAuthor, affiliations: affiliationIndex !== -1 ? [affiliationIndex] : [] };
        const newAuthors = editingAuthorIndex !== null
            ? authors.map((author, index) => index === editingAuthorIndex ? updatedAuthor : author)
            : [...authors, updatedAuthor];

        setAuthors(newAuthors);
        setAffiliations(newAffiliations);
        updatePendingRef.current = true;
        handleClose();

        // Rearrange affiliations based on new author affiliations
        rearrangeAffiliations(newAuthors, newAffiliations);
    };

    const rearrangeAffiliations = (authorsList, affiliationsList) => {
        let updatedAffiliations = [];
        authorsList.forEach(author => {
            author.affiliations.forEach(affiliationIndex => {
                if (!updatedAffiliations.some(a => a.institution === affiliationsList[affiliationIndex].institution && a.country === affiliationsList[affiliationIndex].country)) {
                    updatedAffiliations.push(affiliationsList[affiliationIndex]);
                }
            });
        });

        // Update affiliation indexes in authors
        const updatedAuthors = authorsList.map(author => {
            const newAffiliations = author.affiliations.map(affiliationIndex => {
                return updatedAffiliations.findIndex(a => a.institution === affiliationsList[affiliationIndex].institution && a.country === affiliationsList[affiliationIndex].country);
            });
            return { ...author, affiliations: newAffiliations };
        });

        setAffiliations(updatedAffiliations);
        setAuthors(updatedAuthors);
        updatePendingRef.current = true;
    };

    useEffect(() => {
        if (updatePendingRef.current) {
            props.handleInputChange('authors', authors);
            props.handleInputChange('affiliations', affiliations);
            updatePendingRef.current = false;
        }
    }, [authors, affiliations, props]);

    const deleteAuthor = (index) => {
        const updatedAuthors = authors.filter((_, i) => i !== index);
        rearrangeAffiliations(updatedAuthors, affiliations);
    };

    const deleteAffiliation = (index) => {
        // Remove the affiliation from the affiliations list
        const updatedAffiliations = affiliations.filter((_, i) => i !== index);

        // Update authors to remove the deleted affiliation and adjust the indexes
        const updatedAuthors = authors.map(author => {
            const updatedAuthorAffiliations = author.affiliations
                .filter(affiliationIndex => affiliationIndex !== index) // Remove the deleted affiliation
                .map(affiliationIndex => (affiliationIndex > index ? affiliationIndex - 1 : affiliationIndex)); // Adjust indexes
            return { ...author, affiliations: updatedAuthorAffiliations };
        });

        setAffiliations(updatedAffiliations);
        setAuthors(updatedAuthors);
        rearrangeAffiliations(updatedAuthors, updatedAffiliations);
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reorderedAuthors = Array.from(authors);
        const [movedAuthor] = reorderedAuthors.splice(result.source.index, 1);
        reorderedAuthors.splice(result.destination.index, 0, movedAuthor);
        rearrangeAffiliations(reorderedAuthors, affiliations);
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{editingAuthorIndex !== null ? 'Edit Author' : 'Add Author'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Given Name"
                        type="text"
                        fullWidth
                        name="givenName"
                        value={currentAuthor?.givenName}
                        onChange={handleAuthorChange}
                    />
                    <TextField
                        margin="dense"
                        label="Family Name"
                        type="text"
                        fullWidth
                        name="familyName"
                        value={currentAuthor?.familyName}
                        onChange={handleAuthorChange}
                    />
                    <TextField
                        margin="dense"
                        label="Institution"
                        type="text"
                        fullWidth
                        name="institution"
                        value={currentAffiliation?.institution}
                        onChange={handleAffiliationChange}
                    />
                    <TextField
                        margin="dense"
                        label="Country"
                        type="text"
                        fullWidth
                        name="country"
                        value={currentAffiliation?.country}
                        onChange={handleAffiliationChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={currentAuthor?.presenting}
                                onChange={handleCheckboxChange}
                                name="presenting"
                            />
                        }
                        label="Presenting"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={saveAuthorAndAffiliation}>Save</Button>
                </DialogActions>
            </Dialog>
            <br />
            <div>Authors:
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="authorsDroppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                    display: 'flex',
                                    gap: '8px',
                                    alignItems: 'center',
                                    backgroundColor: snapshot.isDraggingOver ? '#f0f0f0' : 'transparent',
                                    padding: '8px',
                                    borderRadius: '4px'
                                }}
                            >
                                {authors.map((author, index) => (
                                    <Draggable key={'auth' + index} draggableId={'auth' + index} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    ...provided.draggableProps.style,
                                                    display: 'inline-block',
                                                    opacity: snapshot.isDragging ? 0.5 : 1,
                                                    transition: 'all 0.2s ease'
                                                }}
                                            >
                                                <Chip
                                                    label={
                                                        <span style={{ textDecoration: author.presenting ? 'underline' : 'none' }}>
                                                            {parse(`${author.givenName} ${author.familyName} <sup>${author?.affiliations.map(n => n + 1).join(', ')}</sup>`)}
                                                        </span>
                                                    }
                                                    onClick={() => handleOpen(index)}
                                                    onDelete={() => deleteAuthor(index)}
                                                    deleteIcon={<CloseIcon />}
                                                    style={{ borderRadius: '4px' }}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                                <Chip
                                    label="+ Add Author"
                                    onClick={() => handleOpen()}
                                    style={{
                                        backgroundColor: '#1976d2',
                                        color: 'white',
                                        borderRadius: '4px'
                                    }}
                                />
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <br />
            <div>
                Affiliations:
                <Stack direction="row" spacing={1}>
                    {affiliations.map((affiliation, index) => (
                        <Chip
                            key={'aff' + index}
                            label={parse(
                                `<sup>${index + 1}</sup> ${affiliation?.institution}, ${affiliation?.country}`
                            )}
                            onDelete={() => deleteAffiliation(index)}
                            deleteIcon={<CloseIcon />}
                            style={{ borderRadius: '4px' }}
                        />
                    ))}
                </Stack>
            </div>
        </div>
    );
};
