import React, {useEffect, useState} from 'react';
import {Box, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {Logout} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import parse from 'html-react-parser';
import {useForm, useFormDispatch} from '../reducers';

export default function Header(props) {
    const {form, language} = useForm();
    let menuLanguage = language;
    let languageOptions = form.meta.submissionLanguages || [];

    const dispatch = useFormDispatch();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleLanguageMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageSelect = (code) => {
        dispatch({type: 'SET_LANGUAGE', value: code || 'en'});
        if (code === 'ar' || code === 'zh' ) {
            document.body.dir = 'rtl';
        } else {
            document.body.dir = 'ltr';
        }
        handleLanguageMenuClose();
    };

    // Check if the language is in the languageOptions array
    useEffect(() => {
        if(form.meta.submissionLanguages) {
            // languageOptions = form.meta.submissionLanguages;
            if (!languageOptions.includes(language)) {
                console.log('here')
                // eslint-disable-next-line react-hooks/exhaustive-deps
                menuLanguage = form.meta.defaultLanguage || 'en';
                dispatch({type: 'SET_LANGUAGE', value: menuLanguage});
            }
        }
    }, [form])

    return (
        <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)">
            <Toolbar>
                <Typography variant="h6" sx={{flexGrow: 1}}>
                    {parse(form.meta.name)}
                </Typography>
                <IconButton color="inherit" onClick={handleLanguageMenuOpen}>
                    {menuLanguage}
                    <ArrowDropDownIcon/>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleLanguageMenuClose}
                >
                    {languageOptions.map(option => (
                        <MenuItem key={option} onClick={() => handleLanguageSelect(option)}>
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
                <IconButton color="inherit" onClick={() => props.logout()}>
                    <Logout/>
                </IconButton>
            </Toolbar>
        </Box>
    )
}
