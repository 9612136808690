import React, {useEffect, useMemo} from 'react';
import parse from "html-react-parser";
import {I18n as i18n} from "aws-amplify/utils";
import documentIcon from '../assets/fileIcons/document.svg';
import excelIcon from '../assets/fileIcons/ms-excel.svg';
import wordIcon from '../assets/fileIcons/ms-word.svg';
import pdfIcon from '../assets/fileIcons/pdf.svg';
import csvIcon from '../assets/fileIcons/csv.svg';
import imageIcon from '../assets/fileIcons/image.svg';
import {Button} from "@mui/material";
import {htmlToPDF} from "../plugins/htmlToPDF"
import {useForm} from '../reducers';

export default function ActionButton(props) {
    const {submission, control} = props;
    const attributes = JSON.parse(control?.attributes);
    const {parameters, onClick, label, icon, template, dataSource} = attributes.buttonSettings;
    const {form} = useForm();

    const getIconForButton = (icon) => {
        const iconMap = {
            'xlsx': excelIcon,
            'xls': excelIcon,
            'csv': csvIcon,
            'doc': wordIcon,
            'docx': wordIcon,
            'pdf': pdfIcon,
            'png': imageIcon,
            'jpg': imageIcon,
            'jpeg': imageIcon,
            'gif': imageIcon,
            // Add more file extensions as needed
        };

        return iconMap[icon] || documentIcon;
    };

// Function to handle button click based on the type
    const handleButtonClick = (buttonClick) => {
        switch (buttonClick) {
            case 'payment':
                handlePaymentClick();
                break;
            case 'pdf':
                handlePdfClick();
                break;
            default:
                console.log('Unknown button type');
        }
    };

    const handlePaymentClick = () => {
        const {apiKey, profile, fee, link} = parameters;
        if (link && apiKey && profile && fee) {
            const queryString = `?apiKey=${encodeURIComponent(apiKey)}&profile=${encodeURIComponent(profile)}&fee=${encodeURIComponent(submission.data[fee])}`;
            window.location.href = `${link}${queryString}`;
        }
    };

    const handlePdfClick = () => {
        const report = form.meta.reports.find(r => r.fileName === template)
        console.log(report)
        htmlToPDF(submission,form.meta, report.template, {filename: report.fileName});
        console.log('make PDF')
    };

    return (
        <div>
            {parse(i18n.get(control.content))}
            <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: '10px'}}>
                <Button
                    variant="contained"
                    onClick={() => handleButtonClick(onClick)}
                    style={{display: 'flex', alignItems: 'center'}} // Ensures text and icon are aligned
                >
                    {i18n.get(label)}
                    <img
                        src={getIconForButton(icon)}
                        alt="Button Icon"
                        style={{marginLeft: '10px', height: '20px', filter: 'invert(1)'}} // Makes the icon white
                    />
                </Button>
            </div>
        </div>
    );
}
