import * as React from "react";
import parse from "html-react-parser";
import {StorageManager} from "@aws-amplify/ui-react-storage";
import {useForm} from "../reducers";
import {useTheme} from '@mui/material/styles';
import {Button, Divider, Flex, Text} from "@aws-amplify/ui-react";
import {useEffect, useState} from "react";
import {I18n as i18n} from "aws-amplify/utils";

import documentIcon from '../assets/fileIcons/document.svg';
import excelIcon from '../assets/fileIcons/ms-excel.svg';
import wordIcon from '../assets/fileIcons/ms-word.svg';
import pdfIcon from '../assets/fileIcons/pdf.svg';
import csvIcon from '../assets/fileIcons/csv.svg';
import imageIcon from '../assets/fileIcons/image.svg';
import {useAlert} from "../alertContext";

export default function FileUpload(props) {
    const maxFileCount = JSON.parse(props.attributes)?.maxFileCount || 1;
    const acceptedFileTypes=JSON.parse(props.attributes)?.accept || ["*"]

    const {triggerAlert} = useAlert();

    const {submissions, submissionId} = useForm();
    let submission = submissions.find((submission) => submission.id === submissionId);

    const [files, setFiles] = useState(null);
    const theme = useTheme();

    const handleFileList = props.handleFileList;
    useEffect(() => {
        if (files) {
            handleFileList(files)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files])

    useEffect(() => {
        setFiles(submission?.files || []);
    }, [submission?.files]);

    const matchingFilesCount = files?.filter(file => file.dataKey === props.dataKey).length || 0;
    const isDisabled = matchingFilesCount >= maxFileCount;

    const [forceUpdate, setForceUpdate] = useState(0); // Initialize a state variable

    const handleRemoveFile = async (fileKey, dataKey) => {
        // props.handleInputChange(dataKey, false)
        console.log('remove files')
        setFiles(files.filter(file => file.fileKey !== fileKey));
        setForceUpdate((forceUpdate) => forceUpdate + 1); // Increment the state variable to force re-render
    };

    const processFile = async ({file}) => {
        if (files) {
            const fileExtension = file.name.split(".").pop();

            return file
                .arrayBuffer()
                .then((filebuffer) => window.crypto.subtle.digest("SHA-1", filebuffer))
                .then((hashBuffer) => {
                    // console.log(file)
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    const hashHex = hashArray
                        .map((a) => a.toString(16).padStart(2, "0"))
                        .join("");

                    const fileKey = `${hashHex}.${fileExtension}`;
                    const newFile = {
                        id: hashHex,
                        dataKey: props.dataKey,
                        fileKey: fileKey,
                        hash: hashHex,
                        uploaded: new Date().toISOString(),
                        size: file.size,
                        name: file.name,
                    };

                    const currentVersion = files.find((file) => file.hash === newFile.hash);
                    if (!currentVersion) {
                        console.log('new files')
                        setFiles([...files, newFile]);
                        // props.handleInputChange(props.dataKey, true)
                    } else {
                        triggerAlert({
                            message: `That's a duplicate file. It has not been uploaded`,
                            severity: "error",
                            vertical: "bottom",
                            horizontal: "center",
                        });
                        setForceUpdate((forceUpdate) => forceUpdate + 1); // Increment the state variable to force re-render
                    }
                    return {file, key: fileKey};
                });
        }
    };

    const getIconForFile = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const extensionToIconMap = {
            'xlsx': excelIcon,
            'xls': excelIcon,
            'csv': csvIcon,
            'doc': wordIcon,
            'docx': wordIcon,
            'pdf': pdfIcon,
            'png': imageIcon,
            'jpg': imageIcon,
            'jpeg': imageIcon,
            'gif': imageIcon,
            // Add more file extensions as needed
        };

        return extensionToIconMap[extension] || documentIcon;
    };

    return (<>
        <label htmlFor={props.dataKey}>{parse(i18n.get(props?.label||""))}</label>
        <StorageManager
            key={forceUpdate}
            id={props.dataKey}
            acceptedFileTypes={acceptedFileTypes}
            accessLevel="guest"
            maxFileCount={maxFileCount}
            processFile={processFile}
            isResumable
            onUploadStart={({key}) => {
                console.log(key);
            }}
            onUploadSuccess={({key}) => {
                console.log('uploaded', key)
            }}
            onUploadError={(error, {key}) => {
                console.log("error", key, error);
            }}
            components={{
                DropZone({children, inDropZone, displayText, ...rest}) {
                    if (isDisabled) {
                        return null;
                    }

                    return (<Flex
                        alignItems="center"
                        direction="column"
                        padding="medium"
                        backgroundColor={inDropZone ? "brand.primary.10" : ""}
                        {...rest}
                    >
                        <Text>Drop files here</Text>
                        <Divider size="small" label="or" maxWidth="10rem"/>
                        {children}
                        {Array.isArray(files) && (
                            <Text>
                                {files.filter((file) => file.dataKey === props.dataKey).length}{" "}
                                / {maxFileCount} files uploaded
                            </Text>)}
                    </Flex>);
                },
                FilePicker({onClick}) {
                    return (<Button
                        style={{backgroundColor: theme.palette.info.dark, color: 'white'}}
                        onClick={onClick}
                        disabled={isDisabled}
                    >
                        Browse Files
                    </Button>);
                }, FileListHeader() {
                    return <></>;
                }, FileList({files}) {
                    /*     console.log(onCancelUpload);
                         console.log(onDeleteUpload);*/
                    // console.log(files);
                    return <></>;
                },
            }}
        />
        {Array.isArray(files) && (
            <Flex direction="column" padding="small">
                {files
                    .filter((file) => file.dataKey === props.dataKey)
                    .map((file) => (<Flex key={file.hash} alignItems="center">
                        <img src={getIconForFile(file.name)} alt="File Icon" style={{ marginRight: '8px', height:'30px' }} />
                        <Text>{file.name}</Text>
                        <Button
                            onClick={() => handleRemoveFile(file.fileKey, file.dataKey)}
                            marginLeft="auto"
                        >
                            Remove
                        </Button>
                    </Flex>))}
            </Flex>)}
    </>);
}
