import {useFormDispatch, useForm} from "../reducers";
import parse from "html-react-parser";
import {DataStore} from "@aws-amplify/datastore";
import {Submissions} from "../models";
import {DataGrid} from "@mui/x-data-grid";
import {styled} from '@mui/material/styles';
import {Box} from "@mui/material";
import {I18n as i18n} from "aws-amplify/utils";
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {useEffect, useState} from "react";

export default function SubmissionList(props) {
    const dispatch = useFormDispatch();
    const context = useForm()
    const {form, user, pageNumber} = context;
    const [submissions, setSubmissions] = useState(context.submissions);

    const controlAttributes = JSON.parse(props.control.attributes);
    const headerNames = controlAttributes.fieldLabels || [];
    const defaultData = controlAttributes.defaultData;

    const updateSubmissions = async () => {
        const updatedSubmissions = await DataStore.query(Submissions);
        setSubmissions(updatedSubmissions);
    };

    useEffect(() => {
        console.log('list initialised')
        updateSubmissions();
    }, []);

    useEffect(() => {
        const subscription = DataStore.observe(Submissions).subscribe(msg => {
            if (msg.opType === 'UPDATE') {
                console.log('DataStore update:', msg.element);
                updateSubmissions();
            }
        });

        return () => subscription.unsubscribe();
    }, []);

    // set the data
    let rows = submissions.map((submission) => {
        // console.log('set rows')
        let rowData = {id: submission.id};
        controlAttributes.fields.forEach((fieldPath) => {
            // Split the field path and reduce to get the nested value
            const fieldParts = fieldPath.split('.');
            let currentValue = submission;
            for (let part of fieldParts) {
                currentValue = currentValue && currentValue[part] ? currentValue[part] : null;
                if (currentValue === null) {
                    break;
                }
            }
            if (fieldPath === 'meta.complete') {
                currentValue = currentValue ? '✓' : '✕';
            } else if (typeof currentValue === 'boolean') {
                currentValue = currentValue ? '✓' : '✕';
            }
            // Check if currentValue is percentComplete and format it
            if (fieldPath === 'meta.percentComplete') {
                currentValue = currentValue = {
                    isProgress: true,
                    value: currentValue
                };
            }
            // Replace dots with underscores in field keys for DataGrid compatibility
            rowData[fieldPath.replace(/\./g, '_')] = currentValue;
        });

        return rowData;
    });

    // get the field widths
    let widths = [];

    rows.forEach((r, i) => {
        widths[i] = [];
        Object.keys(r).forEach((f) => {
            let l;
            switch (typeof r[f]) {
                case "string":
                    l = r[f].length;
                    break;
                case "boolean":
                    l = 5;
                    break;
                case "number":
                    l = r[f].toString().length;
                    break;
                default:
                // l = 0;
            }
            widths[i].push(l);
        });
    });

    // find the largest width for each column
    let maxWidths = [];
    if (rows.length) {
        try {
            maxWidths = widths[0].map((_, index) =>
                Math.max(...widths.map((subArray) => subArray[index]))
            );
        } catch (e) {
            console.log(e);
        }
        maxWidths.shift();
        maxWidths = maxWidths.map((width, index) => Math.max(width, headerNames[index].length));
    }

    const BorderLinearProgress = styled(LinearProgress)(({theme, value}) => ({
        height: 10,
        borderRadius: 5,
        width: 120,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            // Use hex codes for amber and green colors
            backgroundColor: value < 100 ? '#ff9800' : '#4CAF50',
        },
    }));

// Adjust the renderCell function to pass the progressValue as a prop to BorderLinearProgress
    let cols = controlAttributes.fields.map((fieldPath, i) => {
        const dataGridFieldKey = fieldPath.replace(/\./g, '_');

        return {
            field: dataGridFieldKey,
            headerName: headerNames[i],
            headerClassName: 'boldHeader',
            flex: maxWidths[i] || 0,
            renderCell: (params) => {
                if (params.value && params.value.isProgress) {
                    const progressValue = params.value.value;
                    // Pass the progressValue as a prop here
                    return (<BorderLinearProgress variant="determinate" value={progressValue}/>)
                }
                return <span>{params.value}</span>;
            }
        };
    });

/*
    async function newSubmission() {
        const team = [];
        const newId = uuidv4();

        await DataStore.save(
            new Submissions({
                meta: {
                    page: 0,
                    submissionLanguage:'en',
                    owner: JSON.stringify({
                        familyName: user?.familyName,
                        givenName: user?.givenName,
                        email: user?.email,
                        userId: user?.id,
                    }),
                    creator: JSON.stringify({
                        familyName: user?.familyName,
                        givenName: user?.givenName,
                        email: user?.email,
                        userId: user?.id,
                    }),
                    team: team,
                    percentComplete:0,
                    complete:false,
                    trackingNumber: null
                },
                id: newId,
                data: defaultData,
                files:[],
                hidden:false,
                formToken: form.token,
                token: "",
                checked:false,
                projectToken: form.projectToken,
                userId: user?.id,
            })
        )
            .then(() => {
                console.log(submissions)
                dispatch({type: "LOAD_SUBMISSIONS", value: submissions});
            })
            .then(() => {
                load(newId)
            })
            .catch(e => {
                console.log(e)
            });
    }
*/
    async function newSubmission() {
        const team = [];

        //Datastore will take care of the new submission's ID
        const newSubmission = new Submissions({
            meta: {
                page: 0,
                submissionLanguage:'en',
                owner: JSON.stringify({
                    familyName: user?.familyName,
                    givenName: user?.givenName,
                    email: user?.email,
                    userId: user?.id,
                }),
                creator: JSON.stringify({
                    familyName: user?.familyName,
                    givenName: user?.givenName,
                    email: user?.email,
                    userId: user?.id,
                }),
                team: team,
                percentComplete: 0,
                complete: false,
                trackingNumber: null
            },
            data: defaultData,
            files: [],
            hidden: false,
            formToken: form.token,
            token: "",
            checked: false,
            projectToken: form.projectToken,
            userId: user?.id,
        });

        try {
            // Save the new submission to DataStore
            const savedSubmission = await DataStore.save(newSubmission);

            // Update the state with the new submission
            setSubmissions([...submissions, savedSubmission]);

            // Dispatch the updated submissions list
            dispatch({ type: "LOAD_SUBMISSIONS", value: [...submissions, savedSubmission] });

            // Load the new submission
            load(savedSubmission.id);

        } catch (e) {
            console.log(e);
        }
    }

    function load(id) {
        if (!id) {
            console.warn('Attempted to load submission with invalid id:', id);
            return; // Exit early if ID is invalid
        }
        dispatch({type: "SET_SUBMISSION_ID", value: id});
        dispatch({type: "SET_PAGE_NUMBER", value: pageNumber + 1});
        // props.handleSubmissionChange(id)
    }

    const columnHeaderProp = headerNames.length ? {} : {columnHeaderHeight: 0};
    return (
        <>
            <p>{parse(i18n.get(props.control.content))}</p>
            {rows && rows.length > 0 && (
                <Box mb={2}>
                    <DataGrid
                        autoWidth
                        rows={rows}
                        columns={cols}
                        {...columnHeaderProp}
                        sx={{
                            '& .MuiDataGrid-row:hover': {
                                backgroundColor: 'transparent', // Ensure the row background doesn't change on hover
                                color: 'blue',
                                fontWeight: 'bold',
                            },
                        }}
                        onRowClick={(rowParams) => load(rowParams.id)}
                        hideFooter={true}
                    />
                </Box>)}
            {rows.length < (controlAttributes.maxSubmissions ?? 1) && controlAttributes.addNewButton && (
                <div key={props.currentKey + "1"}>
                    <button
                        onClick={() => {
                            newSubmission();
                        }}
                    >
                        {i18n.get(controlAttributes?.addNewLabel)}
                    </button>
                </div>
            )}
        </>
    );
}
