// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const NavButtonTypes = {
  "NEXT": "NEXT",
  "PREVIOUS": "PREVIOUS",
  "CANCEL": "CANCEL",
  "SUBMIT": "SUBMIT",
  "GOTO": "GOTO",
  "DONE": "DONE"
};

const ControlTypes = {
  "AUTHORS": "AUTHORS",
  "AFFILIATIONS": "AFFILIATIONS",
  "CHECKBOX": "CHECKBOX",
  "DATETIME": "DATETIME",
  "FILE": "FILE",
  "INFORMATION": "INFORMATION",
  "MATHS": "MATHS",
  "RADIO": "RADIO",
  "RATING": "RATING",
  "BUTTON": "BUTTON",
  "SLIDER": "SLIDER",
  "SWITCH": "SWITCH",
  "SPONSOR": "SPONSOR",
  "SUBMISSIONLIST": "SUBMISSIONLIST",
  "LANGUAGES": "LANGUAGES",
  "TABLE": "TABLE",
  "TEXT": "TEXT",
  "TOGGLE": "TOGGLE",
  "SELECT": "SELECT",
  "SUMMARY": "SUMMARY",
  "MESSAGE": "MESSAGE"
};

const { Accounts, Projects, Submissions, Forms, Users, Tokens, Counters, Files, Rules, Policies, UserForms, NavButtons, Flags, Controls, Pages, SubmissionMetadata, Dictionary, TeamMember } = initSchema(schema);

export {
  Accounts,
  Projects,
  Submissions,
  Forms,
  Users,
  Tokens,
  Counters,
  NavButtonTypes,
  ControlTypes,
  Files,
  Rules,
  Policies,
  UserForms,
  NavButtons,
  Flags,
  Controls,
  Pages,
  SubmissionMetadata,
  Dictionary,
  TeamMember
};
