import { useEffect } from "react";
import { useForm } from "../reducers";
import { Hub } from "aws-amplify/utils";
import { DataStore } from "@aws-amplify/datastore";
import { Submissions } from "../models";

const EventsComponent = () => {
  const { submissions, submissionId, user, form } = useForm();

  async function sendEmailHandler(message) {
    console.log("send email");
    const url =
      "https://2dh2gzttk2gil7ah6fccqsvr3y0erkpc.lambda-url.eu-west-1.on.aws/";

    const data = {
      // TODO
      email: user.email,
      subject: message.subject,
      message: message.body,
      replyTo: message.replyTo || []
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function getCurrentSubmission() {
    return await DataStore.query(Submissions, submissionId);
  }

  async function trackingNumberHandler() {
    let currentSubmission = submissions.find(
      (item) => item.id === submissionId
    );
    if (currentSubmission.meta.trackingNumber) {
      return;
    }
    console.log("trackingNumberHandler submissionID: ", submissionId);
    const url =
      "https://u4q7f5sad56o5qxxhfm57xtpyy0hmbml.lambda-url.eu-west-1.on.aws/";

    // Construct the data payload
    const data = {
      id: submissionId,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        //TODO - use appsync to get the tracking number so client can sync
        getCurrentSubmission().then((currentSubmission) => {
          DataStore.save(
            Submissions.copyOf(currentSubmission, (updatedSubmission) => {
              updatedSubmission.meta.trackingNumber = data.trackingNumber;
            })
          );
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  // Event listener setup
  useEffect(() => {
    if (submissionId) {
      // console.log('set up event handling', submissionId)
      const listener = Hub.listen("events", async (data) => {
        const { payload } = data;
        console.log (payload.event);
        switch (payload.event) {
          case "complete":
            // fetch the latest version of the submission
            getCurrentSubmission().then((currentSubmission) => {
              if (currentSubmission.meta.complete) {
                return;
              }
              DataStore.save(
                Submissions.copyOf(currentSubmission, (updatedSubmission) => {
                  updatedSubmission.meta.complete = true;
                })
              ).then(() => {
                console.log("complete saved");
                const messages = form.meta.messages;
                if (messages?.onComplete) {
                  sendEmailHandler(messages?.onComplete);
                }
                trackingNumberHandler();
              });
            });
            break;
          default:
            console.log("Unhandled event:", data.event);
        }
      });

      return () => {
        listener();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionId]);

  return null;
};

export default EventsComponent;
